.services-content {
    padding: 40px;
    background-color: #f0f2f5;
    text-align: center;
  }
  
  .services-details {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .service-item {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
  }
  
  .service-item h3 {
    margin-top: 0;
  }