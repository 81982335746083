.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #333;
    color: #fff;
  }
  
  .logo {
    font-size: 24px;
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
  }
  
  .nav-links li {
    display: inline;
  }
  
  .nav-button {
    background-color: #27a745;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
  }