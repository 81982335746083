.features {
    padding: 50px;
    background-color: #374357;
  }
  
  .feature-list {
    display: flex;
    gap: 20px;
    justify-content: center;
  }
  
  .feature-item {
    background-color: #e9e9e9;
    padding: 20px;
    border-radius: 8px;
    width: 250px;
  }
  
  .feature-item h3 {
    margin-top: 0;
  }